<template>
  <div ref="testResultForm" class="border p-4">
    <div class="hodo-print">
      <div class="overflow-hidden mb-3">
        <div class="text-center inline-block float-left">
          <h4 class="text-xl font-bold mb-0">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.authority_in_charge
                : ""
            }}
          </h4>
          <h5 class="text-lg font-bold mb-0">
            {{
              $globalClinic && $globalClinic.organization
                ? $globalClinic.organization.name
                : ""
            }}
          </h5>
          <h6 class="text-base font-bold mb-0">
            {{ $globalClinic && $globalClinic.name }}
          </h6>
        </div>
        <div class="inline-block float-right">
          <div class="text-base">
            <label class="mr-1">MS:</label>
            <span>09/BV-01</span>
          </div>
          <div class="text-base">
            <label class="mr-1">Số vào viện:</label>
            <span>{{ person.ref_id }}</span>
          </div>
        </div>
      </div>

      <div class="text-center mb-3">
        <h1 class="text-center text-2xl font-bold uppercase mb-0">
          {{
            isHasResultExamination
              ? `Kết quả ${
                  (indicator && indicator.OriginalName) || "xét nghiệm"
                }`
              : `Phiếu ${(indicator && indicator.OriginalName) || "xét nghiệm"}`
          }}
        </h1>
        <!--        <h3-->
        <!--          v-if="indicator && indicator.OriginalName"-->
        <!--          class="text-xl font-bold"-->
        <!--        >-->
        <!--          {{ indicator.OriginalName }}-->
        <!--        </h3>-->
      </div>

      <div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Họ và tên người bệnh:</label>
            <span class="font-bold">{{ patientInfo.name }}</span>
          </div>
          <div class="inline-block" style="width: 25%">
            <label class="mr-1">Năm sinh:</label>
            <span class="font-bold">{{ patientInfo.birthYear }}</span>
          </div>
          <div class="inline-block" style="width: 25%">
            <label class="mr-1">Giới tính:</label>
            <span class="font-bold">{{ patientInfo.gender }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%">
            <label class="mr-1">Địa chỉ:</label>
            <span class="font-bold">{{ patientInfo.address }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Đối tượng:</label>
            <span class="font-bold"></span>
          </div>
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Bác sĩ chỉ định:</label>
            <span class="font-bold">
              {{ indicator.HodoDoctorName }}
            </span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%">
            <label class="mr-1">Khoa:</label>
            <span class="font-bold">{{
              indicator.indicator_w_s && indicator.indicator_w_s.name
            }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Phòng/ Buồng:</label>
            <span class="font-bold">{{ person.admission_room }}</span>
          </div>
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Giường:</label>
            <span class="font-bold">{{ person.admission_bed }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%">
            <label class="mr-1">Chẩn đoán:</label>
            <span class="font-bold">{{ diagnose }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Người lấy mẫu:</label>
            <span class="font-bold">{{ generalInfo.sample_taker_name }}</span>
          </div>
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Thời gian lấy mẫu:</label>
            <span class="font-bold">{{ generalInfo.take_sample_at }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Người nhận mẫu:</label>
            <span class="font-bold">{{
              generalInfo.sample_receiver_name
            }}</span>
          </div>
          <div class="inline-block" style="width: 50%">
            <label class="mr-1">Thời gian nhận mẫu:</label>
            <span class="font-bold">{{ generalInfo.received_sample_at }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%">
            <label class="mr-1">Tình trạng mẫu:</label>
            <span class="font-bold">{{
              getStatusSampleTest(generalInfo.sample_status)
            }}</span>
          </div>
        </div>
        <div class="mb-2">
          <div class="inline-block" style="width: 100%">
            <label class="mr-1">Tình trạng bệnh nhân khi lấy mẫu:</label>
            <div class="inline-block">
              <label class="ml-2" v-for="opt in patientStatusList" :key="opt">
                <input
                  class="align-middle"
                  type="radio"
                  name="patient_status"
                  :value="generalInfo.patient_status"
                />
                <span class="align-middle ml-1">{{ opt }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="hodo-table mb-3">
        <!-- Result Test -->
        <table class="w-full" collapse border="1">
          <thead>
            <tr>
              <th class="text-center">Xét nghiệm</th>
              <th class="text-center">Kết quả</th>
              <th class="text-center">Đơn vị</th>
              <th class="text-center">Bình thường</th>
              <th class="text-center">Máy XN</th>
              <th class="text-center">Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="indicator && indicator.result.length">
              <tr v-for="(row, index) in tableData" :key="index">
                <template v-if="row.is_full_row && row.is_full_row">
                  <td colspan="6">
                    <div class="font-bold">{{ row.test_name }}</div>
                  </td>
                </template>
                <template v-else>
                  <td>
                    <div>{{ row.test_name }}</div>
                  </td>
                  <td>
                    <div
                      class="text-center"
                      :class="{ 'font-bold': row.is_alert }"
                    >
                      <span>{{ row.result }}</span>
                      <span
                        class="ml-1"
                        :style="
                          row.low_high === 'H'
                            ? 'color: red !important'
                            : row.low_high === 'L'
                            ? 'color: blue !important'
                            : ''
                        "
                        v-if="row.low_high !== ''"
                        >({{ row.low_high }})</span
                      >
                    </div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.unit }}</div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.normal_rate }}</div>
                  </td>
                  <td>
                    <div class="text-center">{{ row.test_machine }}</div>
                  </td>
                  <td>
                    <div :class="!row.note ? 'text-center' : ''">
                      {{ row.note || "-" }}
                    </div>
                  </td>
                </template>
              </tr>
            </template>
            <tr v-else>
              <td colspan="6">
                <div class="text-center">
                  Không có dữ liệu kết quả xét nghiệm
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-3">
        <div class="text-center align-top inline-block" style="width: 50%">
          <div class="text-base mb-2">
            {{ dateFormat(indicator.RecordDate) }}
          </div>
          <div class="text-lg mb-5 font-bold">Bác sĩ chỉ định</div>
          <div class="text-base text-center">
            {{ indicator.HodoDoctorName }}
          </div>
        </div>
        <div class="text-center align-top inline-block" style="width: 50%">
          <div class="text-base mb-2">
            {{ dateFormat(indicator.EffectiveDate) }}
          </div>
          <div class="text-lg mb-5 font-bold">Bác sĩ xét nghiệm</div>
          <!-- <div class="text-base text-center">
            {{ indicator.HodoDoctorName }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import { mapState } from 'vuex'

export default {
  name: 'TestResultForm',
  mixins: [mixinGetPersonInfo],
  props: {
    indicator: Object,
    emrIndicationRead: Object,
    links: Array
  },
  data () {
    const generalInfoInitial = {
      diagnose: '',
      sample_taker_name: '',
      take_sample_at: '',
      sample_receiver_name: '',
      received_sample_at: '',
      patient_status: 0
    }

    return {
      isLoading: true,
      checkList: [],
      tableData: [],
      generalInfoInitial,
      generalInfo: generalInfoInitial,
      appUtils,
      patientStatusList: ['No', 'Đói', 'Dùng thuốc', 'Khác'],
      printed: false
    }
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain
    }),
    patientInfo () {
      return {
        name: this.person.name,
        birthYear: this.person.birthday
          ? window.moment(this.person.birthday).format('YYYY')
          : '',
        gender: appUtils.getGender(this.person.gender),
        address: this.getAddress(this.person)
      }
    },
    diagnose () {
      let result = ''
      if (
        this.medicalRecordMain &&
        this.medicalRecordMain.treat_dept_main_code
      ) {
        result += this.medicalRecordMain.treat_dept_main_code
      }
      if (
        this.medicalRecordMain &&
        this.medicalRecordMain.treat_dept_main_name
      ) {
        result = result + '-' + this.medicalRecordMain.treat_dept_main_name
      }

      if (result === '' && this.generalInfo?.diagnose.length) {
        result += this.generalInfo?.diagnose
      }

      return result
    },
    uploadedLinks () {
      return (
        this.indicator?.result?.documents?.filter((f) => f.type === 2) || []
      )
    },
    isHasResultExamination () {
      console.log(this.tableData)
      return (
        this.tableData?.length > 0 &&
        this.tableData.some(
          (el) => el?.result && el?.result?.length > 0 && el?.result !== '-'
        )
      )
    }
  },
  async mounted () {
    await this.handleMounted()
  },
  watch: {
    // emrIndicationRead: {
    //   handler (data) {
    //     this.handleMounted(data)
    //   },
    //   deep: true,
    //   immediate: true
    // },
    links: {
      async handler (data) {
        if (data?.length > 0) {
          const hasResultFile = data.some((link) => {
            return link?.name?.includes(`_${this.emrIndicationRead?.id}_`)
          })
          if (!hasResultFile && this.tableData.length > 0 && !this.isLoading) {
            await this.handlePrint()
          }
        } else {
          if (this.tableData?.length > 0 && !this.isLoading) {
            await this.handlePrint()
          }
        }
      }
    }
  },
  methods: {
    makeResult (exam, index) {
      let lowHigh =
        Number(exam?.ServiceResult?.RangeLower) !==
        Number(exam?.ServiceResult?.RangeUpper)
          ? !isNaN(
            Number(
                exam?.ServiceResult?.ResultValue === ''
                  ? 'NULL'
                  : exam?.ServiceResult?.ResultValue
            )
          )
            ? Number(exam?.ServiceResult?.ResultValue) >
              Number(exam?.ServiceResult?.RangeUpper)
              ? 'H'
              : Number(exam?.ServiceResult?.ResultValue) <
                Number(exam?.ServiceResult?.RangeLower)
                ? 'L'
                : ''
            : ''
          : ''
      return {
        test_name: exam?.ServiceName,
        result: exam?.ServiceResult?.ResultValue || '-',
        unit: exam?.ServiceResult?.Unit || '-',
        low_high: lowHigh,
        normal_rate:
          Number(exam?.ServiceResult?.RangeLower) !==
          Number(exam?.ServiceResult?.RangeUpper)
            ? `${exam?.ServiceResult?.RangeLower} - ${exam?.ServiceResult?.RangeUpper}`
            : '-',
        test_machine: exam?.device || '-',
        note: exam?.note || '',
        is_alert: lowHigh !== ''
      }
    },
    async handleMounted (data) {
      this.tableData = []
      this.generalInfo = {
        ...this.generalInfoInitial
      }
      const id = data?.ID || this.indicator?.ID

      if (!id) return

      try {
        const result = this.indicator?.result || []
        this.tableData = []

        result.forEach((exam_group) => {
          this.tableData = exam_group?.HasSubService
            ? [
              ...this.tableData,
              {
                test_name: exam_group?.ServiceName,
                is_full_row: !!exam_group?.HasSubService
              }
            ]
            : [...this.tableData]
          const resultArr = exam_group?.SubService?.length
            ? exam_group?.SubService?.map((exam, idx) => {
              return this.makeResult(exam, idx)
            })
            : [exam_group]?.map((exam, idx) => {
              return this.makeResult(exam, idx)
            })
          this.tableData = [...this.tableData, ...resultArr]
        })
        this.isLoading = false
      } catch (err) {
        console.log(err)
        this.$message({
          message: 'Lấy dữ liệu xét nghiệm không thành công',
          type: 'warning'
        })
      } finally {
        this.$emit('setTableIndicationsResult', this.tableData)
      }
    },
    changeCheckbox (val) {
      this.generalInfo.patient_status = val
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.is_full_row) {
        if (columnIndex === 0) {
          return [1, 6]
        } else return [0, 0]
      }
    },
    getStatusSampleTest (data) {
      switch (Number(data)) {
        case 1:
          return this.$t('Đạt')
        case 2:
          return this.$t('Không đạt')
        default:
          return ''
      }
    },
    async savePdfToS3 (base64) {
      if (!base64) return

      try {
        const date = window
          .moment(this.emrIndicationRead?.date)
          .format('HHmmssDDMMYYYY')
        const payload = {
          content: base64,
          fileName: `${appUtils.convertSlug(
            `${this.$t('Kết quả xét nghiệm')}`
          )}_${this.emrIndicationRead?.id}_`,
          path: `xncls/${this.emrIndicationRead?.id}_${date || 'unknown'}`,
          type: 'print_form',
          type_id: 'print_form_id',
          uuid: ''
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          const linkData = {
            name: `${date}_${
              this.emrIndicationRead?.id
            }_KQXN_${appUtils.convertSlug(this.emrIndicationRead?.name)}`,
            url: res.data?.documentLink
          }
          this.$emit('saveNewLink', linkData)
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handlePrint () {
      if (this.printed) return

      try {
        this.$emit('setIsLoadingDetail', true)
        const tempHTML = appUtils.templateHTMLPrint({
          title: document.title,
          bodyHTML: this.$refs.testResultForm.innerHTML,
          appendStyle: true
        })
        const base64Data = appUtils.base64.decode(tempHTML)
        await this.savePdfToS3(base64Data)
        this.printed = true
        this.$emit('submitLinks', this.indicator?.result?.id)
      } catch (error) {
        console.log(error)
      } finally {
        this.$emit('setIsLoadingDetail', false)
      }
    },
    dateFormat (value) {
      const date = value ? new Date(value) : new Date()

      return `Ngày ${date.getDate().toString().padStart(2, '0')} tháng ${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')} năm ${date.getFullYear()}`
    }
  }
}
</script>
